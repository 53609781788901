import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { IResStock } from 'src/app/model.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends ApiService{
  public url: string = this.getBaseUrl();

  AutoSetupTOTPVerification(param:any):Observable<any> {
    const header = this.headerBase();
    return this.http.post(this.url + 'admin/AutoSetupTOTPVerification',param,{headers:header});
  }

  loadpost_byproducttype_all(type: string): Observable<any> {
    const header = this.headerBase();
    return this.http.get(this.url + 'post_3apps/selmany_by_producttype_all/' + type, { headers: header });
  }
}
